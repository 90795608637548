import { faTelescope } from '@fortawesome/pro-duotone-svg-icons';
import { faHome } from '@fortawesome/pro-regular-svg-icons';

import Error, { buttonAction } from './Error';

export default function NotFound() {
  return (
    <Error
      title="Hmm,"
      icon={faTelescope}
      text="Looks like this page doesn’t exist. Let’s get you back on track."
      buttonProps={{
        icon: faHome,
        text: 'Go Home',
        action: buttonAction.HOME
      }}
    />
  );
}
