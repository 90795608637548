import { faGrid2, faWindow } from '@fortawesome/pro-regular-svg-icons';

import { useConfigContext } from 'lib/core/config';

import Error from './Error';
import Icon from './Icon';

const ISOLATED_PAGES: string[] = [];

export default function DesktopOnly() {
  const { config } = useConfigContext();

  const canBeOpenedInIsolation = ISOLATED_PAGES.includes(window.location.pathname);
  const suffixText = canBeOpenedInIsolation ? (
    <span>
      You can open this page in desktop view from the <Icon icon={faGrid2} size={15} /> menu in the sidebar.
    </span>
  ) : (
    `You can either change the page or view ${config.BRAND.productName} on a bigger screen.`
  );

  return (
    <Error
      relative
      hidePrimaryAction
      title="The Screen Isn’t Big Enough"
      icon={faWindow}
      text={
        <span>
          This page isn’t supported yet in the {config.BRAND.productName} softphone view.{'\n\n'}
          {suffixText}
        </span>
      }
      hideFooter
    />
  );
}
