import connectAction from 'lib/common/utils/connectAction';
import connectGetter from 'lib/common/utils/connectGetter';

const AGENT_CLOSE_ERROR_MESSAGE =
  'We tried to end the task but something went wrong. Please try again. If the problem persists, try reloading the page.';

export default async function hangupAgentHelper({
  contactId,
  agent
}: {
  contactId?: string;
  agent: connect.Agent | null;
}) {
  if (!agent || !contactId) {
    return Promise.resolve();
  }

  const getAgentContactById = () =>
    connectGetter(agent, 'getContacts')?.find((contact) => contact.contactId === contactId);
  const isContactEnded = () =>
    connectGetter(getAgentContactById(), 'getState')?.type === connect.ContactStateType.ENDED;

  const contact = getAgentContactById();
  const agentConnection = connectGetter(contact, 'getAgentConnection');

  if (!agentConnection || isContactEnded()) {
    return Promise.resolve();
  }

  return connectAction('destroy', agentConnection, void 0, {
    errorMsg: AGENT_CLOSE_ERROR_MESSAGE,
    ignoreError: isContactEnded
  });
}
