import { useHistory } from 'react-router-dom';
import cx from 'classnames';
import { faLayerGroup, faPlus } from '@fortawesome/pro-regular-svg-icons';

import { SOFTPHONE_PAGE_ROUTES } from '@cloud-wave/neon-common-lib/app';

import Button from 'lib/common/components/Button';
import Icon from 'lib/common/components/Icon';
import Text from 'lib/common/components/Text';
import { usePresenceContext } from 'lib/common/contexts/PresenceContext';
import { PRESENCE_STATES } from 'lib/common/constants/presenceStates';
import CreateTaskPopover from 'lib/common/components/atoms/CreateTaskPopover';
import { useSoftphoneModalContext } from 'lib/common/contexts/SoftphoneModalContext';
import SoftphoneCreateAgentTask from 'lib/common/components/molecules/SoftphoneCreateAgentTask';
import SoftphoneSendSms from 'lib/common/components/molecules/SoftphoneSendSms';
import usePresenceComponent from 'lib/common/hooks/usePresence/usePresenceComponent';
import PopoverMenu from 'lib/common/components/PopoverMenu';
import useIsSmallSoftphone from 'lib/common/hooks/useIsSmallSoftphone';

import styles from './landing.module.scss';
import { faDialpad } from 'lib/common/customIcons';

const SOFTPHONE_PRESENCE_DROPDOWN_MAX_HEIGHT = '200px';

function getPlaceholderText({ isBusyFromConnect, agentPresence }) {
  if (agentPresence === PRESENCE_STATES.AVAILABLE || isBusyFromConnect) {
    return 'You are ready to start receiving tasks.';
  }

  return 'Change your status to start receiving tasks.';
}

export default function Landing() {
  const { agentPresence, isBusyFromConnect } = usePresenceContext();
  const { anchorButton, presenceOptions } = usePresenceComponent({});
  const history = useHistory();
  const {
    actions: { openModal, closeModal }
  } = useSoftphoneModalContext();
  const isSmallSoftphone = useIsSmallSoftphone();

  const openCreateAgentTaskModal = () => {
    openModal({
      title: 'Add Agent Task',
      content: <SoftphoneCreateAgentTask onClose={closeModal} />
    });
  };

  const openSendSmsModal = () => {
    openModal({
      title: 'Send a New SMS',
      content: <SoftphoneSendSms onClose={closeModal} />
    });
  };

  const onMakeCall = () => {
    history.push(SOFTPHONE_PAGE_ROUTES.DIALPAD);
  };

  return (
    <>
      <div className={styles['softphone-landing']}>
        <Icon icon={faLayerGroup} size={isSmallSoftphone ? 40 : 60} />
        <div className={cx(styles['softphone-landing__text'], 'mt-30')}>
          <Text type="heading2">No Active Tasks</Text>
          <Text className="mt-10">{getPlaceholderText({ agentPresence, isBusyFromConnect })}</Text>
        </div>

        <div className={cx(styles['softphone-landing__actions'], 'mt-20')}>
          <div className={cx('mb-30')}>
            <PopoverMenu closeOnClick maxHeight={SOFTPHONE_PRESENCE_DROPDOWN_MAX_HEIGHT} anchor={anchorButton}>
              {presenceOptions}
            </PopoverMenu>
          </div>
          <CreateTaskPopover
            onCreateTask={openCreateAgentTaskModal}
            onSendSms={openSendSmsModal}
            anchor={
              <Button
                ariaLabel="Create a new task"
                data-testid="create-task-button"
                styleType="SECONDARY"
                icon={faPlus}
                noCapitalizedContent
              >
                Create a New
              </Button>
            }
          />
          <Button
            styleType="SECONDARY"
            icon={faDialpad}
            onClick={onMakeCall}
            data-testid="make-call-button"
            noCapitalizedContent
          >
            Make a Call
          </Button>
        </div>
      </div>
    </>
  );
}
