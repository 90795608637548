import { ReactNode } from 'react';
import { FontAwesomeIcon, FontAwesomeIconProps } from '@fortawesome/react-fontawesome';
import { IconDefinition } from '@fortawesome/fontawesome-svg-core';
import cx from 'classnames';

import COLOURS from 'css/export-vars.module.scss';
import Tooltip from 'lib/common/components/atoms/Tooltip';

import './icon.scss';

export enum IconSizeConstraints {
  HEIGHT = 'HEIGHT',
  WIDTH = 'WIDTH'
}

interface IconProps extends Omit<FontAwesomeIconProps, 'size' | 'icon'> {
  className?: string;
  color?: string;
  size?: number;
  icon: IconDefinition;
  tooltip?: ReactNode;
  tooltipDelay?: number;
  onlyConstrain?: IconSizeConstraints;
  tooltipPlacement?:
    | 'bottom-end'
    | 'bottom-start'
    | 'bottom'
    | 'left-end'
    | 'left-start'
    | 'left'
    | 'right-end'
    | 'right-start'
    | 'right'
    | 'top-end'
    | 'top-start'
    | 'top';
  tooltipArrow?: boolean;
  button?: boolean;
  ariaHidden?: boolean;
}

function getIconDimension({
  sizePx,
  height,
  width
}: {
  sizePx: string;
  height?: string | number;
  width?: string | number;
}) {
  return {
    fontSize: sizePx,
    width: width || sizePx,
    height: height || sizePx
  };
}

const Icon = ({
  className = '',
  color,
  size = 20,
  tooltip,
  icon,
  tooltipDelay = 1000,
  tooltipPlacement = 'bottom',
  tooltipArrow = false,
  onlyConstrain,
  button,
  ariaHidden = true,
  ...iconProps
}: IconProps) => {
  const sizePx = `${size}px`;
  const dimensions = getIconDimension({ sizePx, width: iconProps.width, height: iconProps.height });

  const iconEl = (
    <FontAwesomeIcon
      className={cx('base-icon', {
        [className]: !tooltip,
        'base-icon--constrain-height': onlyConstrain === IconSizeConstraints.HEIGHT,
        'base-icon--constrain-width': onlyConstrain === IconSizeConstraints.WIDTH
      })}
      color={color ? COLOURS[color] : COLOURS.darkBlue}
      style={dimensions}
      icon={icon}
      {...iconProps}
    />
  );

  return tooltip ? (
    <Tooltip
      enterDelay={tooltipDelay}
      title={<span style={{ whiteSpace: 'pre-wrap' }}>{tooltip}</span>}
      placement={tooltipPlacement}
      arrow={tooltipArrow}
      classes={{ arrow: 'tooltip-arrow' }}
      ariaHidden={ariaHidden}
    >
      {button ? (
        <button className={cx('base-icon-container', 'no-styles-button', className)}>{iconEl}</button>
      ) : (
        <div className={cx('base-icon-container', className)}>{iconEl}</div>
      )}
    </Tooltip>
  ) : (
    iconEl
  );
};

export default Icon;
