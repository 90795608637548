import { useState } from 'react';
import cx from 'classnames';
import _debounce from 'lodash.debounce';
import { ContactType, Permissions } from '@cloud-wave/neon-common-lib';

import { useConfigContext } from 'lib/core/config';
import { useLayout } from 'lib/common/contexts/layout/LayoutContext';
import Button from 'lib/common/components/Button';
import SegmentedControls from 'lib/common/components/SegmentedControls';

import { useContactContext } from 'lib/common/contexts/ContactContext';
import CONTACT_STATES from 'lib/common/constants/contactStates';
import { usePermissionsContext } from 'lib/common/contexts/PermissionsContext';
import { TTransferDestination } from 'lib/common/contexts/OverlayContext/Context';
import { useSoftphoneModalContext } from 'lib/common/contexts/SoftphoneModalContext';
import useTrigger from 'lib/common/hooks/useTrigger';

import ColouredPanel, { PanelTypes } from '../../atoms/ColouredPanel';
import CreateUpdateDirectoryContact from './components/CreateUpdateDirectoryContact';
import ContactList from './components/ContactList';
import Searchbar from './components/Searchbar/Searchbar';
import { DIRECTORY_TAB } from './constants/directoryTabs';
import getTabOptions from './utils/getTabOptions';
import { TInternalContacts } from './types';

import useTransfers from './components/ContactList/hooks/useTransfers';

import styles from './directory.module.scss';
import { faPlus } from '@fortawesome/pro-regular-svg-icons';

const SEARCH_DEBOUNCE_TIME = 700;

export interface DirectoryProps {
  initialSelectedTab?: DIRECTORY_TAB;
  transferTo?: TTransferDestination;
  onClose?: () => void;
  pushToTask?: (url: string) => void;
  resetTitle?: () => void;
  setTitle?: (title: string) => void;
}

enum DirectoryPages {
  List,
  CreateUpdate
}

const Directory = ({
  initialSelectedTab = DIRECTORY_TAB.AGENTS,
  transferTo,
  onClose,
  setTitle,
  resetTitle
}: DirectoryProps) => {
  const [page, setPage] = useState(DirectoryPages.List);

  const {
    actions: { getSelectedTask }
  } = useContactContext();
  const [currentTab, setCurrentTab] = useState(initialSelectedTab);
  const [searchTerm, setSearchTerm] = useState('');
  const [contact, setContact] = useState<TInternalContacts | undefined>(undefined);
  const [reloadDataTrigger, reloadData] = useTrigger();
  const { isSoftphone } = useLayout();
  const { config } = useConfigContext();
  const { hasPermission, isAdmin } = usePermissionsContext();
  const {
    actions: { openModal, closeModal }
  } = useSoftphoneModalContext();

  const { transferringToId, onTransfer } = useTransfers({ closeModal: onClose, isSoftphone });

  const fetchUrl = (type: string, searchTerm: string) =>
    `${config.DIRECTORY_SERVICE_URL}/user/contacts?type=${type.toLowerCase()}` +
    (searchTerm ? `&filter=${encodeURIComponent(searchTerm)}` : '');

  const hasPersonalContactPermission = hasPermission({
    type: 'tenant',
    permission: Permissions.PERSONAL_CONTACTS
  });

  const tabOptions = getTabOptions({
    hasPersonalContactPermission,
    isSoftphone,
    transferTo
  });

  const canCreateContact =
    (!transferTo && currentTab === DIRECTORY_TAB.PERSONAL) ||
    (!transferTo && currentTab === DIRECTORY_TAB.ORGANISATION && isAdmin);

  const handleSearch = _debounce((toSearch: string) => {
    setSearchTerm(toSearch);
  }, SEARCH_DEBOUNCE_TIME);

  const selectedTask = getSelectedTask();
  const isTaskInactive = !selectedTask || selectedTask?.status === CONTACT_STATES.ACW;

  const onCreateOrUpdateContactClick = (contact?: TInternalContacts) => {
    const title = contact ? `Update ${contact.firstName} ${contact.lastName}` : 'Create A New Contact';

    if (isSoftphone) {
      return void openModal({
        title,
        content: (
          <CreateUpdateDirectoryContact
            contact={contact}
            onCancel={closeModal}
            onSuccess={() => {
              reloadData();
              closeModal();
            }}
            defaultType={currentTab === DIRECTORY_TAB.ORGANISATION ? ContactType.ORGANISATION : ContactType.PERSONAL}
          />
        )
      });
    }

    setTitle?.(title);
    setContact(contact);
    setPage(DirectoryPages.CreateUpdate);
  };

  if (!isSoftphone && page === DirectoryPages.CreateUpdate) {
    return (
      <CreateUpdateDirectoryContact
        onCancel={() => {
          resetTitle?.();
          setPage(DirectoryPages.List);
        }}
        contact={contact}
        defaultType={currentTab === DIRECTORY_TAB.ORGANISATION ? ContactType.ORGANISATION : ContactType.PERSONAL}
      />
    );
  }

  return (
    <div className={cx(styles['directory'], { [styles['directory--softphone']]: isSoftphone })}>
      <div className={styles['directory__search']} data-testid="directory__search">
        <Searchbar onChange={handleSearch} searchTerm={searchTerm} />
        {canCreateContact && (
          <Button
            ariaLabel="Create contact"
            icon={faPlus}
            data-testid="create-contact"
            size="medium"
            onClick={() => onCreateOrUpdateContactClick()}
          />
        )}
      </div>
      {tabOptions.length > 1 && (
        <SegmentedControls
          ariaLabel="Contact type"
          initialIndices={tabOptions.findIndex((tab) => tab.label === currentTab)}
          onSelect={(tabName: string) => setCurrentTab(tabName as DIRECTORY_TAB)}
          options={tabOptions}
        />
      )}
      {isTaskInactive && transferTo && !transferringToId && (
        <ColouredPanel className={styles['directory__banner']} type={PanelTypes.WARNING}>
          You can no longer transfer as the task has ended.
        </ColouredPanel>
      )}
      <ContactList
        onCreateOrUpdateClick={onCreateOrUpdateContactClick}
        onDeleteContact={reloadData}
        key={`${currentTab}${searchTerm}${reloadDataTrigger}`}
        selectedTab={currentTab}
        searchTerm={searchTerm}
        onClearSearchTerm={() => setSearchTerm('')}
        fetchUrl={fetchUrl}
        isAdmin={isAdmin}
        isTransfer={Boolean(transferTo)}
        transferDisabled={isTaskInactive}
        transferringToId={transferringToId}
        onTransfer={onTransfer}
      />
    </div>
  );
};

export { Directory };
