import { useEffect, useState } from 'react';
import cx from 'classnames';

import Button from 'lib/common/components/Button';
import { useContactContext } from 'lib/common/contexts/ContactContext';
import { useLayout } from 'lib/common/contexts/layout/LayoutContext';
import {
  areAllThirdPartyConnectionsConnected,
  areAllThirdPartyConnectionsOnHold,
  getAgentConnection,
  getAllActiveConnections,
  isAnyConnectionConnecting,
  isAnyConnectionOnHold
} from 'lib/common/utils/conferenceConnections';
import connectGetter from 'lib/common/utils/connectGetter';
import { ACTIONS_VISIBILITY_TIMEOUT } from 'lib/common/constants/conference';

import styles from './conference-sub-footer.module.scss';
import { faArrowRightToBracket, faExchange } from '@fortawesome/pro-regular-svg-icons';

export default function ConferenceSubFooter({ taskId, contact }) {
  const [showButtons, setShowButtons] = useState<{ swapVisible: boolean; joinVisible: boolean }>({
    swapVisible: false,
    joinVisible: false
  });
  const [isSwapping, setIsSwapping] = useState(false);
  const {
    actions: { onJoinConference, onSwapConferenceCall }
  } = useContactContext();
  const { isSoftphone } = useLayout();
  const agentConnection = getAgentConnection(contact);
  const isSwapDisabled = connectGetter(agentConnection, 'getState')?.type === connect.ConnectionStateType.HOLD;
  const isJoinDisabled = isSwapping && showButtons.swapVisible && !isSwapDisabled;
  const isConnecting = isAnyConnectionConnecting(contact);
  const allThirdPartyConnectionsOnHold = areAllThirdPartyConnectionsOnHold(contact);
  const allThirdPartyConnectionsConnected = areAllThirdPartyConnectionsConnected(contact);
  const activeConnectionsLength = getAllActiveConnections(contact).length;
  const isSwapVisible =
    activeConnectionsLength <= 2 && !allThirdPartyConnectionsOnHold && !allThirdPartyConnectionsConnected;
  const isJoinVisible = isAnyConnectionOnHold(contact);

  useEffect(() => {
    if (!isSwapVisible && !isJoinVisible) {
      return void setShowButtons({ swapVisible: false, joinVisible: false });
    }

    // Show buttons after a timeout to prevent flickering when swapping or connections are changing
    const visibleTimeout = setTimeout(() => {
      setShowButtons({ swapVisible: isSwapVisible, joinVisible: isJoinVisible });
    }, ACTIONS_VISIBILITY_TIMEOUT);

    return () => {
      clearTimeout(visibleTimeout);
    };
  }, [isSwapVisible, isJoinVisible]);

  if (!showButtons.joinVisible) {
    return null;
  }

  return (
    <div
      className={cx(styles['conference-sub-footer'], {
        [styles['conference-sub-footer--softphone']]: isSoftphone
      })}
    >
      <div className={styles['conference-sub-footer__button-container']}>
        <Button
          onClick={() => onJoinConference({ taskId, includeAgent: true })}
          icon={faArrowRightToBracket}
          tooltip="Join everyone on the call"
          styleType="SECONDARY"
          asyncAction
          successTimeoutSeconds={0}
          preventDoubleClick
          size="small"
          className={styles['conference-sub-footer__button']}
          fullWidth
          disabled={isJoinDisabled || isConnecting}
        >
          Join
        </Button>
      </div>
      {showButtons.swapVisible && (
        <div className={styles['conference-sub-footer__button-container']}>
          <Button
            onClick={() => onSwapConferenceCall(taskId)}
            onButtonDisabledChange={(isButtonDisabled) => setIsSwapping(isButtonDisabled)}
            tooltip={`${isSwapDisabled ? 'You are unable to swap while on hold' : 'Swap the person on hold'}`}
            icon={faExchange}
            styleType="SECONDARY"
            asyncAction
            successTimeoutSeconds={0}
            preventDoubleClick
            size="small"
            className={styles['conference-sub-footer__button']}
            fullWidth
            disabled={isSwapDisabled || isConnecting}
          >
            Swap
          </Button>
        </div>
      )}
    </div>
  );
}
