import cx from 'classnames';
import _isEmpty from 'lodash.isempty';
import { faDeleteLeft, faPeopleArrowsLeftRight, faPhone } from '@fortawesome/pro-regular-svg-icons';

import Button from 'lib/common/components/Button';
import ClickableIcon from 'lib/common/components/ClickableIcon';

import IPanel from '../../types/Panel';

import styles from './actions.module.scss';

interface IActions {
  isDialling?: boolean;
  isDialpadTransfer?: boolean;
  onDialPadCall: () => void;
  onDialPadTransfer: () => void;
  onDialActionSuccess: () => void;
  panel: IPanel | null;
  dialNumber: string;
  handleBackspace: () => void;
  isSoftphone?: boolean;
  isEmergencyNumber?: boolean;
  disabled?: boolean;
}

export default function Actions({
  isDialling,
  isDialpadTransfer,
  onDialPadCall,
  onDialPadTransfer,
  onDialActionSuccess,
  panel,
  dialNumber,
  handleBackspace,
  isSoftphone,
  isEmergencyNumber,
  disabled
}: IActions) {
  const canMakeOutboundCall = !panel || panel?.canMakeOutboundCall;

  const dialpadTransferButton = (
    <Button
      busy={isDialling}
      asyncAction
      icon={faPeopleArrowsLeftRight}
      onClick={onDialPadTransfer}
      onSuccess={onDialActionSuccess}
      disabled={_isEmpty(dialNumber) || !canMakeOutboundCall || disabled}
      successTimeoutSeconds={0}
      className={styles['actions__transfer']}
      size="large"
    >
      Transfer
    </Button>
  );

  const dialpadActions = (
    <>
      <div className={styles['actions__action']} />
      <Button
        ariaLabel="Call"
        busy={isDialling}
        asyncAction
        icon={faPhone}
        onClick={onDialPadCall}
        disabled={_isEmpty(dialNumber) || !canMakeOutboundCall || disabled}
        successTimeoutSeconds={0}
        className={cx(styles['actions__action'], styles['actions__call-button'])}
        styleType={isEmergencyNumber ? 'DANGER' : void 0}
      />
    </>
  );

  return (
    <div
      data-testid="dialpad-actions"
      className={cx(styles['actions'], {
        [styles['actions--softphone']]: isSoftphone
      })}
    >
      {isDialpadTransfer ? dialpadTransferButton : dialpadActions}
      <div className={styles['actions__action']}>
        <ClickableIcon
          aria-label="Backspace"
          disabled={disabled}
          icon={faDeleteLeft}
          onClick={handleBackspace}
          size={isSoftphone ? 23 : 29}
        />
      </div>
    </div>
  );
}
