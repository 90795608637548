import { faEnvelope, faSignature } from '@fortawesome/pro-regular-svg-icons';
import { Permissions } from '@cloud-wave/neon-common-lib';

import Button from 'lib/common/components/Button/Button';
import PreferenceSectionContainer from 'lib/common/components/molecules/Preferences/components/PreferenceSectionContainer';
import { useAgentPreferencesContext } from 'lib/common/contexts/AgentPreferenceContext';
import { usePermissionsContext } from 'lib/common/contexts/PermissionsContext';

interface IPreferencesAudioDevices {}

const PreferencesEmail = ({}: IPreferencesAudioDevices) => {
  const { hasPermission } = usePermissionsContext();

  const {
    actions: { setPreferenceModalOpen }
  } = useAgentPreferencesContext();

  const hasEmailPermission = hasPermission({ type: 'tenant', permission: Permissions.EMAIL });

  if (!hasEmailPermission) {
    return null;
  }

  return (
    <PreferenceSectionContainer icon={faEnvelope} title={'Email'}>
      <Button
        styleType={'SECONDARY'}
        icon={faSignature}
        size={'large'}
        onClick={() => {
          setPreferenceModalOpen(true);
        }}
      >
        Manage Email Signature
      </Button>
    </PreferenceSectionContainer>
  );
};

export default PreferencesEmail;
