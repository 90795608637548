import {
  faEnvelope,
  faCopy,
  faPaste,
  faArrowRight,
  faBrowsers,
  faShieldCheck,
  faHourglass
} from '@fortawesome/pro-duotone-svg-icons';
import { faMessageDots, faEnvelope as farEnvelope } from '@fortawesome/pro-regular-svg-icons';
import Text from 'lib/common/components/Text';
import EmptyPlaceholder from 'lib/common/components/EmptyPlaceholder';
import styles from './playground.module.scss';
import { InfoCard, InfoCardType, InfoCardSize, InfoCardGroup, InfoCardGroupGap } from 'lib/common/components/InfoCard';
import Icon from 'lib/common/components/Icon';
import { useState } from 'react';
import { ControlledSegmentedControls } from 'lib/common/components/SegmentedControls';
import { match } from 'ts-pattern';
import cx from 'classnames';

const InfoCards = () => (
  <div style={{ width: 300, display: 'flex', flexDirection: 'column', gap: 10 }}>
    <InfoCard icon={faEnvelope} text="Large Primary" />
    <InfoCard icon={faEnvelope} text="Large Secondary" type={InfoCardType.SECONDARY} />
    <InfoCard icon={faEnvelope} title="Title" text="Large Primary" />
    <InfoCard icon={faEnvelope} title="Title" text="Large Secondary" type={InfoCardType.SECONDARY} />

    <InfoCard icon={faEnvelope} text="Small Primary" size={InfoCardSize.SMALL} />
    <InfoCard icon={faEnvelope} text="Small Secondary" type={InfoCardType.SECONDARY} size={InfoCardSize.SMALL} />
    <InfoCard icon={faEnvelope} title="Title" text="Small Primary" size={InfoCardSize.SMALL} />
    <InfoCard
      icon={faEnvelope}
      title="Title"
      text="Small Secondary"
      type={InfoCardType.SECONDARY}
      size={InfoCardSize.SMALL}
    />

    <InfoCard icon={farEnvelope} title="Title" text="Large Primary Minimal" minimal />
    <InfoCard icon={farEnvelope} type={InfoCardType.SECONDARY} title="Title" text="Large Secondary Minimal" minimal />
    <InfoCard icon={farEnvelope} text="Large Primary Minimal" minimal />
    <InfoCard icon={farEnvelope} type={InfoCardType.SECONDARY} text="Large Secondary Minimal" minimal />
    <InfoCard icon={farEnvelope} title="Title" text="Small Primary Minimal" size={InfoCardSize.SMALL} minimal />
    <InfoCard
      icon={farEnvelope}
      type={InfoCardType.SECONDARY}
      title="Title"
      text="Small Secondary Minimal"
      size={InfoCardSize.SMALL}
      minimal
    />
    <InfoCard icon={farEnvelope} text="Small Primary Minimal" size={InfoCardSize.SMALL} minimal />
    <InfoCard
      icon={farEnvelope}
      type={InfoCardType.SECONDARY}
      text="Small Secondary Minimal"
      size={InfoCardSize.SMALL}
      minimal
    />
  </div>
);

const VerificationGroup = () => (
  <InfoCardGroup
    alternating
    gap={InfoCardGroupGap.LARGE}
    className={styles['infocard-group']}
    cards={[
      {
        icon: faEnvelope,
        text: (
          <Text>
            You'll recieve a <Text.Bold inline>verification code</Text.Bold> <Icon icon={faMessageDots} size={15} /> in
            your <Text.Bold>email account</Text.Bold> from no‑reply@verificationemail.com.{' '}
            <Text type="link" inline>
              Learn more
            </Text>
            .
          </Text>
        )
      },
      {
        icon: faCopy,
        text: (
          <Text>
            <Text.Bold inline>Copy</Text.Bold> or make a note of your verification code{' '}
            <Icon icon={faMessageDots} size={15} />.
          </Text>
        )
      },
      {
        icon: faPaste,
        text: (
          <Text>
            <Text.Bold inline>Paste</Text.Bold> or type your code in the boxes provided.
          </Text>
        )
      },
      {
        icon: faArrowRight,
        text: (
          <Text>
            Click <Text.Bold inline>Continue</Text.Bold> and we'll finish signing you in.
          </Text>
        )
      }
    ]}
  />
);

const Verification = () => (
  <div className={styles['info-group']} style={{ width: 630, display: 'flex', flexDirection: 'column', gap: 30 }}>
    <InfoCard
      icon={faEnvelope}
      text={
        <Text>
          You'll recieve a <Text.Bold inline>verification code</Text.Bold> <Icon icon={faMessageDots} size={15} /> in
          your <Text.Bold>email account</Text.Bold> from no‑reply@verificationemail.com.{' '}
          <Text type="link" inline>
            Learn more
          </Text>
          .
        </Text>
      }
    />
    <InfoCard
      icon={faCopy}
      text={
        <Text>
          <Text.Bold inline>Copy</Text.Bold> or make a note of your verification code{' '}
          <Icon icon={faMessageDots} size={15} />.
        </Text>
      }
    />
    <InfoCard
      icon={faPaste}
      text={
        <Text>
          <Text.Bold inline>Paste</Text.Bold> or type your code in the boxes provided.
        </Text>
      }
    />
    <InfoCard
      icon={faArrowRight}
      text={
        <Text>
          Click <Text.Bold inline>Continue</Text.Bold> and we'll finish signing you in.
        </Text>
      }
    />
  </div>
);

const SignIn = () => (
  <div style={{ width: 500, display: 'flex', flexDirection: 'column', gap: 20 }}>
    <InfoCard
      type={InfoCardType.SECONDARY}
      icon={faHourglass}
      title="Still Going"
      text={
        <Text>
          We're working hard to sign you in! If you've been waiting for some time you can try{' '}
          <Text type="underline" inline>
            reloading the page
          </Text>
          .
        </Text>
      }
    />
    <InfoCard
      icon={faBrowsers}
      title="Pop-ups"
      text={
        <Text>
          The sign in page will open in a new window or tab, so you'll need to{' '}
          <Text type="link" inline>
            allow popups
          </Text>
          .
        </Text>
      }
    />
    <InfoCard
      icon={faShieldCheck}
      title="Next Steps"
      text="After signing into your provider, the window will close, and we'll continue automatically."
    />
  </div>
);

export default function Playground() {
  const [selectedIndex, setSelectedIndex] = useState(0);
  return (
    <div className={styles['playground']}>
      <ControlledSegmentedControls
        options={[
          { label: 'InfoCard' },
          { label: 'Verification' },
          { label: 'VerificationGroup' },
          { label: 'SignIn' }
        ]}
        selectedIndices={[selectedIndex]}
        setSelectedIndices={([index]) => setSelectedIndex(index)}
      />
      {match(selectedIndex)
        .with(0, () => <InfoCards />)
        .with(1, () => <Verification />)
        .with(2, () => <VerificationGroup />)
        .with(3, () => <SignIn />)
        .otherwise(() => (
          <></>
        ))}
    </div>
  );
}
