import { useHistory } from 'react-router-dom';
import cx from 'classnames';
import { faPlus } from '@fortawesome/pro-regular-svg-icons';
import { faTelescope } from '@fortawesome/pro-duotone-svg-icons';

import * as Profile from 'lib/common/components/Profile';
import Loader from 'lib/common/components/Loader';
import RequiresPermission from 'lib/common/components/RequiresPermission';
import { useContactContext } from 'lib/common/contexts/ContactContext';
import { Permissions } from '@cloud-wave/neon-common-lib';
import useCustomerProfileActions from 'lib/common/hooks/useCustomerProfileActions';
import getContactDisplayName from 'lib/common/utils/customerProfiles/getContactDisplayName';
import Error from 'lib/common/components/Error';

import Summary from './Summary';
import Header from './Header';

import '../styles/customer-profile.scss';

interface ICustomerProfileProps {
  profileId: string;
}

export default function CustomerProfile({ profileId }: ICustomerProfileProps) {
  const {
    state: { tasks, selectedTaskId },
    actions: { makeOutboundCall, matchExistingCustomerProfile }
  } = useContactContext();

  const history = useHistory();

  const matchedTask = tasks.find(({ profile }) => profile?.profileId === profileId);

  // In the case we've added a new profile and there's no current match, trigger a re-search when we know the contact
  const refetch = () => {
    if (matchedTask || !selectedTaskId) {
      return;
    }

    matchExistingCustomerProfile({ taskId: selectedTaskId });
  };

  const onDeleteProfile = () => {
    history.push('/profiles');
  };

  const {
    profile,
    error,
    profileHistory,
    profileNotes,
    showEditModal,
    showDeleteModal,
    profileDoesNotExist,
    profileLoading,
    actions: { deleteProfile, onEdit, onSaveNotes, onDeleteProfileSuccess },
    modalActions: {
      open: { openEditModal, openDeleteModal },
      close: { closeEditModal, closeDeleteModal }
    }
  } = useCustomerProfileActions({ profileId, refetch, onDeleteProfile });

  const callContact = () => {
    if (!profile) {
      return;
    }

    if (!profile.phoneNumber) {
      return;
    }

    makeOutboundCall(profile.phoneNumber);
  };

  const onCreate = () => {
    history.push('/profiles/new');
  };

  return (
    <>
      {profile && (
        <RequiresPermission permission={Permissions.CUSTOMER_PROFILES_WRITE}>
          <Profile.EditProfileModal
            profile={profile}
            onSave={onEdit}
            open={showEditModal}
            onClose={closeEditModal}
            onSuccess={closeEditModal}
          />
        </RequiresPermission>
      )}
      <RequiresPermission permission={Permissions.CUSTOMER_PROFILES_WRITE}>
        <Profile.DeleteProfileDialog
          open={showDeleteModal}
          onClose={closeDeleteModal}
          onConfirm={deleteProfile}
          onSuccess={onDeleteProfileSuccess}
        />
      </RequiresPermission>
      <div className="customer-profile__content">
        <Header
          profile={profile}
          profileNotes={profileNotes}
          currentContactIsViewed={Boolean(matchedTask)}
          onCall={callContact}
          onDelete={openDeleteModal}
          onEdit={openEditModal}
        />
        <div className={cx(`panel${profile ? '--small' : ''}`, 'customer-profile__content__profile')}>
          {profileLoading && <Loader relative small />}
          <Summary profile={profile} currentContactIsViewed={Boolean(matchedTask)} />
          {error && <Error relative hideFooter />}
          {profileDoesNotExist && (
            <Error
              relative
              hideFooter
              title="Hmm,"
              text="This profile doesn’t exist yet. If it was just created, it may take a few seconds to appear. Try reloading below."
              icon={faTelescope}
              buttonProps={{
                icon: faPlus,
                text: 'Create A New Profile',
                onClick: onCreate
              }}
            />
          )}
        </div>
        {!profileDoesNotExist && !profileLoading && (
          <>
            <Profile.Notes
              className="panel--small"
              contactDisplayName={getContactDisplayName(profile, { capitalised: true })}
              notes={profileNotes}
              onSave={onSaveNotes}
            />
            <Profile.History
              className="panel--small mb-30"
              profileHistory={profileHistory}
              currentContactIsViewed={Boolean(matchedTask)}
              emptyMessage={
                Boolean(matchedTask)
                  ? `This is the first time ${getContactDisplayName(profile)} has called.`
                  : `${getContactDisplayName(profile, {
                      capitalised: true
                    })} doesn't have any previous interactions.`
              }
            />
          </>
        )}
      </div>
    </>
  );
}
