import Button from 'lib/common/components/Button';

import { useContactContext } from 'lib/common/contexts/ContactContext';
import TaskFooter from '../TaskFooter';
import TaskFooterRow from '../TaskFooterRow';
import TransferFooterButtons from '../TransferFooterButtons';

import styles from './agent-task-footer.module.scss';
import { faXmark } from '@fortawesome/pro-regular-svg-icons';

interface IAgentTaskProps {
  taskId: string;
  connectionValue: string;
}

export default function AgentTask({ taskId, connectionValue }: IAgentTaskProps) {
  const {
    actions: { hangupAgent }
  } = useContactContext();

  return (
    <TaskFooter>
      <TaskFooterRow isSingleRow className={styles['agent-task-footer__row']}>
        <TransferFooterButtons taskId={taskId} connectionValue={connectionValue} />
        <Button
          round
          size="medium"
          onClick={() => hangupAgent(taskId)}
          icon={faXmark}
          styleType="DANGER"
          tooltip="Close the task"
          asyncAction
          successTimeoutSeconds={0}
          preventDoubleClick
        />
      </TaskFooterRow>
    </TaskFooter>
  );
}
