import Button from 'lib/common/components/Button';
import { useContactContext } from 'lib/common/contexts/ContactContext';
import TaskFooter from './TaskFooter';
import { faXmark } from '@fortawesome/pro-regular-svg-icons';

export default function HangUpTask({ taskId }: { taskId: string }) {
  const {
    actions: { hangupAgent }
  } = useContactContext();

  return (
    <TaskFooter>
      <Button
        onClick={() => hangupAgent(taskId)}
        icon={faXmark}
        tooltip="Hang up"
        round
        size="medium"
        asyncAction
        styleType="DANGER"
      />
    </TaskFooter>
  );
}
