import { faList, faListTree } from '@fortawesome/pro-regular-svg-icons';
import { useEffect, useMemo, useState } from 'react';
import cx from 'classnames';
import { ActionMeta } from 'react-select';

import { useContactContext } from 'lib/common/contexts/ContactContext';
import IWrapUpCode from 'lib/common/types/WrapUpCode';
import Task from 'lib/common/types/Task';
import ChatTask from 'lib/common/types/ChatTask';
import TaskContentTypes from 'lib/common/types/TaskContentTypes';
import PopoverMenuItem from 'lib/common/components/PopoverMenuItem';
import Button from 'lib/common/components/Button';
import PopoverMenu from 'lib/common/components/PopoverMenu';
import WRAP_UP_CODE_TYPES from 'lib/common/constants/ACW/wrapUpCodeTypes';
import { useLayout } from 'lib/common/contexts/layout/LayoutContext';

import List from './components/List';
import Tree from './components/Tree';

import styles from './styles/acw-code-selector.module.scss';

import NO_RESULTS from './constants/noResults';

import generateTree from './utils/generateTree';
import alphabeticallySortCodes from './utils/alphabeticallySortCodes';
import ACWCodeLabel from './components/ACWCodeLabel';

const SELECTOR_VIEW_KEY = 'acw-code-view';

enum SelectorView {
  LIST = 'LIST',
  TREE = 'TREE'
}

interface IACWCodeSelector {
  selectedTask: Task | ChatTask;
  queueWrapUpCodes: IWrapUpCode[];
  loaded: boolean;
  className?: string;
}

function getDefaultSelectorView() {
  const storedView = localStorage.getItem(SELECTOR_VIEW_KEY);

  return storedView === SelectorView.LIST || storedView === SelectorView.TREE ? storedView : SelectorView.LIST;
}

const ACWCodeSelector = ({ selectedTask: { ACW, taskId }, queueWrapUpCodes, loaded, className }: IACWCodeSelector) => {
  const [view, setView] = useState<SelectorView>(getDefaultSelectorView());
  const [code, setCode] = useState<IWrapUpCode | undefined>(ACW?.wrapUpCodeName);

  const { isSoftphone } = useLayout();

  const {
    actions: { setTaskContent }
  } = useContactContext();

  // Build the list options and tree nodes at this level so we don't need to rebuild every time the view changes

  const treeOptions = useMemo(() => generateTree(queueWrapUpCodes), [queueWrapUpCodes]);

  const hasSmallListOptions = useMemo(() => !queueWrapUpCodes.some((code) => code.parentCodeId), [queueWrapUpCodes]);

  const listOptions = useMemo(
    () =>
      alphabeticallySortCodes(queueWrapUpCodes.filter((code) => code.type === WRAP_UP_CODE_TYPES.CODE)).map(
        (wrapUpCode: IWrapUpCode) => {
          return {
            value: wrapUpCode.name,
            label: <ACWCodeLabel isSoftphone={isSoftphone} wrapUpCode={wrapUpCode} small={hasSmallListOptions} />,
            wrapUpCode
          };
        }
      ),
    [queueWrapUpCodes]
  );

  useEffect(() => {
    setCode(ACW?.wrapUpCodeName);
  }, [taskId]);

  useEffect(() => {
    localStorage.setItem(SELECTOR_VIEW_KEY, view);
  }, [view]);

  const noResults = () => {
    return !queueWrapUpCodes.length ? NO_RESULTS.NO_CODES : NO_RESULTS.NO_SEARCH_RESULTS;
  };

  const onChange = (option: unknown, actionMeta?: ActionMeta<unknown>) => {
    const { wrapUpCode } = (option as { wrapUpCode?: IWrapUpCode }) || {};
    const codeToStore = actionMeta?.action === 'clear' ? void 0 : wrapUpCode;

    setCode(codeToStore);

    setTaskContent(taskId, TaskContentTypes.ACW, {
      ...ACW,
      wrapUpCodeName: codeToStore
    });
  };

  return (
    <div className={cx(styles['acw-code-selector'], 'full-width', className)}>
      {view === SelectorView.LIST ? (
        <List loaded={loaded} noResults={noResults} onChange={onChange} selectedCode={code} options={listOptions} />
      ) : (
        <Tree onChange={onChange} loaded={loaded} selectedCode={code} noResults={noResults} options={treeOptions} />
      )}
      <PopoverMenu
        anchor={
          <Button
            styleType="SECONDARY"
            icon={view === SelectorView.LIST ? faList : faListTree}
            size="small"
            tooltip="Change wrap codes view"
            data-testid="wrap-code-view-button"
          />
        }
        closeOnClick
      >
        <PopoverMenuItem
          icon={faList}
          text="List View"
          selected={view === SelectorView.LIST}
          onClick={() => setView(SelectorView.LIST)}
        />
        <PopoverMenuItem
          icon={faListTree}
          text="Tree View"
          selected={view === SelectorView.TREE}
          onClick={() => setView(SelectorView.TREE)}
        />
      </PopoverMenu>
    </div>
  );
};

export default ACWCodeSelector;
