import { createEnum } from '@cloud-wave/neon-common-lib';

export const InfoCardType = createEnum('PRIMARY', 'SECONDARY');
export type TInfoCardType = keyof typeof InfoCardType;

export const InfoCardSize = createEnum('SMALL', 'LARGE');
export type TInfoCardSize = keyof typeof InfoCardSize;

export const InfoCardGroupGap = {
  SMALL: 10,
  MEDIUM: 20,
  LARGE: 30
} as const;
export type TInfoCardGroupGap = typeof InfoCardGroupGap[keyof typeof InfoCardGroupGap];
