import { useEffect, useState } from 'react';
import { usePrevious } from 'webrix/hooks';
import { faCheck, faSpinnerThird, faXmark } from '@fortawesome/pro-regular-svg-icons';
import { IconDefinition } from '@fortawesome/fontawesome-common-types';

import useTimeout from './useTimeout';

const LOADING_STATES = {
  NONE: 'NONE',
  BUSY: 'BUSY',
  SUCCESS: 'SUCCESS',
  ERROR: 'ERROR'
};

interface Props<T> {
  onAction?: (arg0: unknown) => T | Promise<T>;
  onSuccess?: (response?: T) => void;
  onFailure?: (e?: unknown) => void;
  onFinally?: () => void;
  loading?: boolean;
  successTimeoutSeconds?: number;
}

const ICON_TIMEOUT_S = 1;
const BUSY_TIMEOUT_S = 0.3;

const stateIcons = {
  [LOADING_STATES.NONE]: undefined,
  [LOADING_STATES.BUSY]: faSpinnerThird,
  [LOADING_STATES.SUCCESS]: faCheck,
  [LOADING_STATES.ERROR]: faXmark
};

export default function useActionState<T>({
  onAction,
  onSuccess,
  onFailure,
  onFinally,
  loading,
  successTimeoutSeconds = ICON_TIMEOUT_S
}: Props<T>) {
  const [loadingState, setLoadingState] = useState<string>(LOADING_STATES[loading ? 'BUSY' : 'NONE']);
  const previousLoadingState = usePrevious(loadingState);

  const { setTimeoutRef, clearTimeout } = useTimeout();

  const busy = loadingState !== LOADING_STATES.NONE;
  const prefixIcon = !busy ? stateIcons[LOADING_STATES.NONE] : stateIcons[loadingState];

  useEffect(() => {
    const newState = loading ? LOADING_STATES.BUSY : LOADING_STATES.NONE;

    setLoadingState(newState);
  }, [loading]);

  // These need to be handled in this hook and not in handleInteraction, because the parent
  // might change the functions and we need to call the most recent version, not the original reference
  useEffect(() => {
    const loadingStateHasReset = loadingState === LOADING_STATES.NONE && previousLoadingState !== LOADING_STATES.NONE;

    if (!loadingStateHasReset && loadingState !== LOADING_STATES.ERROR) {
      return;
    }

    if (loadingStateHasReset) {
      return void onFinally?.();
    }

    onFailure?.();
  }, [loadingState]);

  const handleInteraction = async (event?: unknown) => {
    clearTimeout();

    setTimeoutRef(() => {
      setLoadingState(LOADING_STATES.BUSY);
    }, BUSY_TIMEOUT_S * 1000);

    try {
      const result = await onAction?.(event);

      if (successTimeoutSeconds) {
        setLoadingState(LOADING_STATES.SUCCESS);
      }

      clearTimeout();

      setTimeoutRef(() => {
        setLoadingState(LOADING_STATES.NONE);
        onSuccess?.(result);
        onFinally?.();
      }, successTimeoutSeconds * 1000);
    } catch (e) {
      setLoadingState(LOADING_STATES.ERROR);

      clearTimeout();

      setTimeoutRef(() => {
        setLoadingState(LOADING_STATES.NONE);
      }, ICON_TIMEOUT_S * 1000);
    }
  };

  return {
    handleInteraction,
    busy,
    icon: prefixIcon,
    icons: {
      busy: stateIcons[LOADING_STATES.BUSY],
      success: stateIcons[LOADING_STATES.SUCCESS],
      error: stateIcons[LOADING_STATES.ERROR]
    }
  };
}

export type ActionState = {
  handleInteraction: (event: any) => Promise<void>;
  busy: boolean;
  icon: undefined | IconDefinition;
  icons: {
    busy: IconDefinition;
    success: IconDefinition;
    error: IconDefinition;
  };
};
