import cx from 'classnames';
import { faEllipsis, faFont, faPaperPlane } from '@fortawesome/pro-regular-svg-icons';
import { TemplateType } from '@cloud-wave/neon-common-lib';
import { useLexicalComposerContext } from '@lexical/react/LexicalComposerContext';
import { $generateNodesFromDOM } from '@lexical/html';
import { $getSelection } from 'lexical';

import Button from 'lib/common/components/Button';
import ClickableIcon from 'lib/common/components/ClickableIcon';
import PopoverMenu from 'lib/common/components/PopoverMenu';

import UploadFilesEmail from 'lib/common/components/molecules/UploadFilesEmail';
import { UploadFilesTypesEmail } from 'lib/common/components/molecules/UploadFilesEmail';
import { EmailModalTypes } from 'lib/common/components/atoms/Email/EmailModal/EmailModal';
import EmailPopover from 'lib/common/components/atoms/Email/EmailPopover';
import TemplatedResponse from 'lib/common/components/molecules/TemplatedResponse';

import UploadedFileType from 'lib/common/constants/files/UploadedFileType';
import { EmailSendTypes } from 'lib/common/hooks/useEmail/api/sendEmail';
import TEmailTask from 'lib/common/types/EmailTask';
import getTemplatedResponseQueueIds from 'lib/common/utils/templatedResponse/getTemplatedResponseQueueIds';
import { EmailSignatureMenu } from 'lib/pages/AgentWorkspace/components/CreateEmail/EmailSignatureMenu/EmailSignatureMenu';

import { MutableRefObject } from 'react';
import styles from './footer.module.scss';

interface IFooter {
  taskId: string;
  toggleToolbar: () => void;
  saveDisabled?: boolean;
  onSend: () => Promise<void | boolean>;
  type: EmailSendTypes;
  discardEmail: () => void;
  setModalVisible: (modalType: null | EmailModalTypes) => void;
  baseFileSizeTotal?: number;
  task?: TEmailTask;
  onSendSuccess: () => Promise<void>;
  submitButtonRef: MutableRefObject<HTMLButtonElement | null>;
}

export default function Footer({
  taskId,
  toggleToolbar,
  saveDisabled,
  onSend,
  discardEmail,
  type,
  setModalVisible,
  baseFileSizeTotal,
  task,
  onSendSuccess,
  submitButtonRef
}: IFooter) {
  const isNewEmail = type === EmailSendTypes.NEW;
  const [editor] = useLexicalComposerContext();

  const onAddTemplateContent = (templateContent: string) => {
    editor.update(() => {
      const stateToChange = editor.getEditorState().toJSON();
      const currentStateChildren = stateToChange.root.children;
      const stuffToAppendChildren = JSON.parse(templateContent).root.children;
      stateToChange.root.children = [...stuffToAppendChildren, ...currentStateChildren];

      const newState = editor.parseEditorState(stateToChange);

      editor.setEditorState(newState);
    });
  };

  const onInsertSignature = (signature: string) => {
    editor.update(() => {
      const selection = $getSelection();
      const parser = new DOMParser();
      const dom = parser.parseFromString('<p/><p/>' + signature, 'text/html');
      const nodes = $generateNodesFromDOM(editor, dom);
      if (selection) {
        selection.insertNodes(nodes);
      }
    });
  };

  const queueIds = getTemplatedResponseQueueIds(task);

  return (
    <div className={cx('task-footer__container', styles['footer__container'])}>
      <Button onClick={discardEmail} styleType="SECONDARY" preventDoubleClick data-testid="discard-email-button">
        Discard
      </Button>
      <div className={styles['footer__container__right-buttons']}>
        {!isNewEmail && (
          <PopoverMenu reverse anchor={<ClickableIcon icon={faEllipsis} className="ml-auto" />}>
            <EmailPopover setModalVisible={setModalVisible} task={task} />
          </PopoverMenu>
        )}
        <TemplatedResponse type={TemplateType.EMAIL} queueIds={queueIds} onClick={onAddTemplateContent} />
        <EmailSignatureMenu onClick={onInsertSignature} />
        <ClickableIcon icon={faFont} onClick={toggleToolbar} tooltip="Text Formatting" />
        <UploadFilesEmail
          contextId={taskId}
          uploadedFileType={UploadedFileType.EMAIL_ATTACHMENT}
          type={UploadFilesTypesEmail.ICON}
          baseFileSizeTotal={baseFileSizeTotal}
        />
        <Button
          ref={submitButtonRef}
          disabled={saveDisabled}
          data-testid="send-email-button"
          onClick={onSend}
          icon={faPaperPlane}
          preventDoubleClick
          asyncAction
          onSuccess={(result) => {
            if (!result) {
              return;
            }

            return onSendSuccess();
          }}
        >
          Send
        </Button>
      </div>
    </div>
  );
}
