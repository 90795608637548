import sizeMe from 'react-sizeme';
import cx from 'classnames';
import { ReactNode } from 'react';
import { IconDefinition } from '@fortawesome/fontawesome-svg-core';
import Icon from 'lib/common/components/Icon';
import DEFAULT_COLUMN_MIN_WIDTH from './constants/defaultColumnMinWidth';
import './column.scss';

type TResizableColumn = {
  minWidth?: number;
  minimisedIcon?: IconDefinition;
  children?: ReactNode;
  size: { width?: number; height?: number };
  noPanel?: boolean;
  className?: string;
  isResizable?: boolean;
  vertical?: boolean;
};

const MINIMISED_WIDTH_THRESHOLD_PX = 50;

const sizeMeHOC = sizeMe({ monitorWidth: true, refreshRate: 16 });

function Column({
  minWidth = DEFAULT_COLUMN_MIN_WIDTH,
  minimisedIcon,
  children,
  size: { width = 0 } = {},
  noPanel,
  className,
  isResizable,
  vertical
}: TResizableColumn) {
  const minimised = minimisedIcon && width <= minWidth + MINIMISED_WIDTH_THRESHOLD_PX;

  return (
    <div
      className={cx('agent-workspace-column', className, {
        panel: !noPanel,
        'panel--no-padding': minimised,
        'agent-workspace-column--resizable': isResizable,
        'agent-workspace-column--vertical': vertical
      })}
    >
      {minimised ? (
        <Icon className="agent-workspace-column__minimised-icon" icon={minimisedIcon} size={40} color="midGrey" />
      ) : (
        children
      )}
    </div>
  );
}

export default sizeMeHOC(Column);
