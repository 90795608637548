import { ReactNode, useState } from 'react';

import Button from 'lib/common/components/Button';
import { useContactContext } from 'lib/common/contexts/ContactContext';
import { useLayout } from 'lib/common/contexts/layout/LayoutContext';
import TaskFooterRow from '../TaskFooterRow';
import TaskFooter from '../TaskFooter';
import TransferFooterButtons from '../TransferFooterButtons';
import ACWOutcomeModal from '../../../ACW/components/ACWOutcomeModal';
import { faNotes, faXmark } from '@fortawesome/pro-regular-svg-icons';

export default function Chat({
  taskId,
  connectionValue,
  children
}: {
  taskId: string;
  connectionValue: string;
  children?: ReactNode;
}) {
  const [isACWModalOpen, setIsACWModalOpen] = useState(false);
  const { isSoftphone } = useLayout();
  const {
    actions: { hangupAgent }
  } = useContactContext();

  return (
    <>
      <TaskFooter isSmallWidth>
        {isSoftphone && children && <TaskFooterRow isSingleRow>{children}</TaskFooterRow>}
        <TaskFooterRow isSingleRow>
          {isSoftphone && (
            <Button
              icon={faNotes}
              round
              styleType="NEUTRAL"
              onClick={() => setIsACWModalOpen(true)}
              preventDoubleClick
              tooltip="Interaction Outcomes"
              size="small"
            />
          )}
          <TransferFooterButtons taskId={taskId} connectionValue={connectionValue} />
          <Button
            icon={faXmark}
            styleType="DANGER"
            round
            size="medium"
            onClick={() => hangupAgent(taskId)}
            preventDoubleClick
            tooltip="End the chat"
            asyncAction
            successTimeoutSeconds={0}
          />
        </TaskFooterRow>
      </TaskFooter>
      {isSoftphone && <ACWOutcomeModal onClose={() => setIsACWModalOpen(false)} isACWModalOpen={isACWModalOpen} />}
    </>
  );
}
