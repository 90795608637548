import { faCheckCircle } from '@fortawesome/pro-regular-svg-icons';

import Button from 'lib/common/components/Button';
import Icon from 'lib/common/components/Icon';
import Text from 'lib/common/components/Text';
import SupportFooter from 'lib/common/components/SupportFooter';
import { useConfigContext } from 'lib/core/config';
import { ReactComponent as SignedOutImage } from './assets/signed-out.svg';

import styles from './signed-out-overlay.module.scss';

export default function SignedOutOverlay({ hideSignInButton }: { hideSignInButton?: boolean }) {
  const { config } = useConfigContext();

  return (
    <div className={styles['signed-out-overlay']}>
      <div>
        <Text className="mb-20" type="heading1">
          We've Signed You Out <Icon className="ml-10" size={25} icon={faCheckCircle} />
        </Text>
        <Text>
          Your {config.BRAND.productName} status has been set to <strong>Offline</strong> and we’ve ended your session.
          You can sign back in by clicking the button below or reloading {config.BRAND.productName}.
        </Text>
        <Text className="mt-20 mb-40">
          <strong>We recommend you always sign out of {config.BRAND.productName} when you finish for the day.</strong>
        </Text>
        {!hideSignInButton && (
          <Button
            ariaLabel="Sign back in"
            onClick={() => {
              window.location.reload();
            }}
          >
            Sign Back In
          </Button>
        )}
      </div>
      <SignedOutImage />
      <SupportFooter />
    </div>
  );
}
