import sizeMe from 'react-sizeme';
import cx from 'classnames';
import { faCircleUser, faUserPlus } from '@fortawesome/pro-regular-svg-icons';
import { Permissions } from '@cloud-wave/neon-common-lib';

import Button from 'lib/common/components/Button';
import Text from 'lib/common/components/Text';
import Icon from 'lib/common/components/Icon';
import RequiresPermission from 'lib/common/components/RequiresPermission';
import ChatTask from 'lib/common/types/ChatTask';
import Task from 'lib/common/types/Task';
import getMatchableAttribute from 'lib/common/utils/getMatchableAttribute';

const sizeMeHOC = sizeMe({ monitorWidth: true, refreshRate: 16 });

const SMALL_WIDTH = 450;

const NotFound = ({
  onCreate,
  task,
  size: { width = 0 }
}: {
  onCreate: () => void;
  task?: ChatTask | Task;
  size: { width?: number };
}) => {
  const isSmall = width < SMALL_WIDTH;
  const canCreate = Boolean(getMatchableAttribute(task?.contact));

  return (
    <>
      <div className={cx('customer-profile__not-found', { 'customer-profile__not-found--small': isSmall })}>
        <div className="customer-profile__not-found__info">
          <Text type="heading2">The current customer doesn’t have a profile</Text>
          <RequiresPermission permission={[Permissions.CUSTOMER_PROFILES, Permissions.CUSTOMER_PROFILES_WRITE]}>
            <Text>
              {!canCreate
                ? 'There isn’t any information we can use to associate them with a new profile.'
                : 'Creating a new profile for this contact will allow you to see previous interactions, add notes and more.'}
            </Text>
          </RequiresPermission>
        </div>
        {canCreate && (
          <RequiresPermission permission={[Permissions.CUSTOMER_PROFILES, Permissions.CUSTOMER_PROFILES_WRITE]}>
            <Button ariaLabel="Create profile" icon={!isSmall ? faUserPlus : undefined} onClick={onCreate}>
              {isSmall ? 'Create Profile' : ''}
            </Button>
          </RequiresPermission>
        )}
      </div>
      {!isSmall && (
        <Icon className="customer-profile__not-found__icon" icon={faCircleUser} color={'midGrey'} size={80} />
      )}
    </>
  );
};

export default sizeMeHOC(NotFound);
