import { ChangeEvent, ReactNode, useRef, useState } from 'react';
import { faPaperclip, faUpload } from '@fortawesome/pro-regular-svg-icons';

import { useEmailUploadContext } from 'lib/common/contexts/EmailUploadContext';
import UploadedFileType from 'lib/common/constants/files/UploadedFileType';
import Button from 'lib/common/components/Button';
import ClickableIcon from 'lib/common/components/ClickableIcon';
import Modal from 'lib/common/components/Modal';
import Text from 'lib/common/components/Text';
import { useLayout } from 'lib/common/contexts/layout/LayoutContext';
import { HELP_LINKS } from 'lib/common/constants/help';

import { useConfigContext } from 'lib/core/config';
import { IFileUploadMetadata } from '../../../contexts/EmailUploadContext';
import validateFiles from './utils/validateFiles';
import DropZone from './components/DropZone';

interface IDropZone {
  children?: ReactNode;
  type: UploadFilesTypesEmail.DROP_ZONE;
}

interface IUploadButton {
  children?: never;
  type: UploadFilesTypesEmail.BUTTON | UploadFilesTypesEmail.ICON;
}

export enum UploadFilesTypesEmail {
  BUTTON = 'BUTTON',
  ICON = 'ICON',
  DROP_ZONE = 'DROP_ZONE'
}

export default function UploadFilesEmail({
  onUploadFiles,
  type,
  children,
  className,
  contextId,
  uploadedFileType,
  helpLink,
  baseFileSizeTotal
}: {
  className?: string;
  contextId: string;
  onUploadFiles?: (files: IFileUploadMetadata[]) => void;
  uploadedFileType: UploadedFileType;
  helpLink?: string;
  baseFileSizeTotal?: number;
} & (IUploadButton | IDropZone)) {
  const {
    actions: { getFilesForContext, uploadFiles }
  } = useEmailUploadContext();
  const { config } = useConfigContext();
  const { isSoftphone } = useLayout();

  const helpLinkPath = HELP_LINKS.EMAIL.SUPPORTED_ATTACHMENTS[isSoftphone ? 'SOFTPHONE' : 'DESKTOP'];
  const defaultHelpLink = `${config.BRAND.helpDocsUrl}${helpLinkPath}`;

  const fileInputRef = useRef<HTMLInputElement | null>(null);
  const [errorModal, setErrorModal] = useState<string | undefined>();

  const onClick = () => {
    fileInputRef?.current?.click();
  };

  const handleFileArray = async (files: File[]) => {
    const validationError = validateFiles(files, getFilesForContext(contextId), baseFileSizeTotal);

    if (validationError) {
      return void setErrorModal(validationError);
    }

    const fileUploadMetadata: IFileUploadMetadata[] = await uploadFiles(uploadedFileType, files, contextId);

    onUploadFiles?.(fileUploadMetadata);
  };

  const onChangeFiles = async (event: ChangeEvent<HTMLInputElement>) => {
    const {
      target: { files }
    } = event;

    if (!files || !files.length) {
      return;
    }

    const fileArray = Array.from(files);

    // Reset the file input so we can select the same file again
    event.target.value = '';

    return handleFileArray(fileArray);
  };

  const onCloseErrorModal = () => {
    setErrorModal(void 0);
  };

  return (
    <>
      <Modal
        onClose={onCloseErrorModal}
        title="Can't Attach One Or More Files"
        hideSecondaryButton
        onSave={onCloseErrorModal}
        primaryButtonText="Close"
        open={Boolean(errorModal)}
        syncAction
      >
        <Text>
          {errorModal}
          <br />
          <br />
          <Text href={helpLink || defaultHelpLink}>Learn more</Text>.
        </Text>
      </Modal>
      {type === UploadFilesTypesEmail.DROP_ZONE ? (
        <DropZone className={className} handleFileArray={handleFileArray}>
          {children}
        </DropZone>
      ) : (
        <>
          {type === UploadFilesTypesEmail.BUTTON && (
            <Button icon={faUpload} onClick={onClick} className={className} data-testid="upload-files-button">
              Upload Attachments
            </Button>
          )}
          {type === UploadFilesTypesEmail.ICON && (
            <ClickableIcon
              className={className}
              icon={faPaperclip}
              onClick={onClick}
              data-testid="upload-files-button"
              tooltip="Attach Files"
            />
          )}
          <input
            type="file"
            id="upload-files"
            ref={fileInputRef}
            className="hide"
            onChange={onChangeFiles}
            multiple
            data-testid="upload-files"
            aria-label="Upload files"
          />
        </>
      )}
    </>
  );
}
