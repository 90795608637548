import cx from 'classnames';
import { IconDefinition } from '@fortawesome/fontawesome-svg-core';
import { useEffect, useRef } from 'react';

import Icon from 'lib/common/components/Icon';
import Tooltip from 'lib/common/components/atoms/Tooltip';

import styles from './tab.module.scss';

const TAB_ORIENTATIONS = {
  ABOVE: 'above',
  BELOW: 'below'
} as const;

const TAB_SIZES = {
  SMALL: 'small',
  LARGE: 'large'
} as const;

export interface ITabProps {
  id: string;
  title?: string;
  marginRight?: number;
  icon: IconDefinition;
  isSelected?: boolean;
  onClick?: () => void;
  orientation?: ValueOf<typeof TAB_ORIENTATIONS>;
  setWidth?: (arg0: number) => void;
  size?: ValueOf<typeof TAB_SIZES>;
  'data-testid'?: string;
  tooltip?: string;
  ariaLabel?: string;
}

export default function Tab({
  isSelected,
  orientation,
  marginRight,
  title,
  icon,
  onClick,
  setWidth,
  size,
  tooltip,
  ariaLabel,
  ...props
}: ITabProps) {
  const ref = useRef<HTMLButtonElement>(null);

  useEffect(() => {
    setWidth?.(ref?.current?.offsetWidth || 0);
  }, [ref.current]);

  const color = isSelected ? 'darkBlue' : 'darkGrey';

  const tab = (
    <button
      style={{ marginRight }}
      data-testid={props['data-testid']}
      ref={ref}
      onClick={onClick}
      className="no-styles-button"
      role="tab"
      aria-selected={isSelected}
      aria-label={ariaLabel || tooltip || title}
    >
      <div
        className={cx(styles['tab'], {
          [styles['tab--selected']]: isSelected,
          [styles['tab--small']]: size === TAB_SIZES.SMALL,
          [styles['tab--inverted']]: orientation === TAB_ORIENTATIONS.BELOW
        })}
      >
        <Icon size={17} color={color} icon={icon} />
      </div>
    </button>
  );

  if (title) {
    return (
      <Tooltip enterDelay={1000} title={title} ariaHidden>
        {tab}
      </Tooltip>
    );
  }

  return tab;
}
